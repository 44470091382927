import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
// import stealth from '@stltio/stealth'

import Logo from './Logo/Logo'
import Nav from './Nav/Nav'
import Menu from './Menu/Menu'
import Footer from './Footer/Footer'
import Slideshow from '../../UI/Slideshow/Slideshow'
import ContactForm from '../../UI/ContactForm/ContactForm'
import ScrollTop from './ScrollTop/ScrollTop'
import Announcements from '../../UI/Announcements/Announcements'
import PageLoader from '../../UI/PageLoader/PageLoader'

import {
  loadCourses,
  loadInstructors,
  loadFeedbacks,
  loadAnnouncements
} from '../../../redux/actions'

const Layout = ({ announcement, auth }) => {
  const dispatch = useDispatch()
  let location = useLocation()

  // useEffect(() => {
  //   stealth({
  //     apiKey: 'f5b1292fd5524cf7c8620a2d7f5977007c74f5e16ca5b618ca3ed1bf41becb49'
  //   })
  // }, [])

  useEffect(() => {
    dispatch(loadCourses())
    dispatch(loadInstructors())
    dispatch(loadFeedbacks())
    dispatch(loadAnnouncements())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <>
      <Logo />
      <Nav />
      <Menu />
      <Announcements list={announcement.list || []} />
      <main>
        <Outlet />
      </main>
      <Slideshow />
      <ContactForm />
      <Footer />
      <ScrollTop />
      {auth.loading && <PageLoader />}
    </>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  loadCourses,
  loadInstructors,
  loadFeedbacks,
  loadAnnouncements
})(Layout)
